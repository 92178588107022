import HTTP from '@/utils/http'

class agreement extends HTTP {
  /**
   * 获取抵金券和lily币兑换券
   */
  getaAgreement(type) {
    return this.get({
      url: `https://cms.dayuan1997.com/api/cmscore/protocol?type=${type}`,
      header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }
}
export default agreement
