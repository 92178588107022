<!--  -->
<template>
  <div class="agreement" v-html="resHtml"></div>
</template>

<script>
import Agreement from '@/models/agreement'
const agreement = new Agreement()
export default {
  data() {
    return {
      resHtml: '',
    }
  },
  mounted() {
    this._initData()
  },
  methods: {
    _initData() {
      agreement.getaAgreement(this.$route.query.type).then((res) => {
        this.resHtml = res
      })
    },
  },
}
</script>
<style lang='stylus' scoped>
.agreement
  width 100%
  padding 30px
  overflow auto
</style>